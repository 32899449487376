import BottomShortcuts from '../BottomShortcuts/BottomShortcut';
import CrandLink from '../CopyrightSection/CrandLink';

export default function Footer() {
  return (
    <div>
      <BottomShortcuts />
      <CrandLink />
    </div>
  )
}
